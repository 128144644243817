* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    height: 100%;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: #f8f9fa;
    border: none;
  }
  
  .login-popup {
    background: #fff;
    padding: 30px 30px 30px 48px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    width: 100%;
    max-width: 450px;
    border: 1px;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 5px 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .login-button {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #007BFF;
  }
  