.dashboard-container {
    display: flex;
    height: 100vh;
  }
  
  .menu-container {
    flex: 0 0 15%; 
    background-color: white;
    padding: 20px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 38px;
  }
  
  .menu-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .menu-container button {
    margin-bottom: 25px;
  }
  
  .logout-button {
    margin-top: auto; 
  }
  
  .component-display {
    flex-grow: 1; 
    padding: 20px;
    overflow-y: auto;
    margin-top: 50px;
    background-color: #F8F9FA;
    border-top-left-radius: 15px;
  }
  
  .highlighted-view {
    background-color: #F0F8FF;
    color: #007BFF;
    border-left: 5px solid #5D5FEF;
    width: 100%;
  }
  
  .normal-view {
    background-color: inherit;
    color: black;
    border-left: none;
    width: 100%;
  }